import React from 'react'
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Heading
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

import NewsletterForm from './NewsletterForm'


const NewsletterModal = (props) => {
    return (
        <Modal
            isOpen={true}
            onClose={props.onClose}
            size='700px'
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                background="black"
            >
                <ModalCloseButton size="lg" color="white" />
                <ModalBody p="4rem">
                    <Heading
                        color="white"
                        fontSize="22px"
                        mb="4rem"
                    >
                        <FormattedMessage id="footer.join_community" />
                    </Heading>
                    <NewsletterForm />
                </ModalBody>
            </ ModalContent>
        </Modal>
    )
}

export default NewsletterModal
