import React from 'react';

const CalameoBook = ({ id }) => {
  return (
    <div>
      <iframe
        src={`//v.calameo.com/?bkcode=${id}&mode=mini&clickto=embed&showsharemenu=false`}
        width="100%"
        height="500"
        frameBorder="0"
        scrolling="no"
        allowTransparency
        allowFullScreen
      />
    </div>
  );
};

export default CalameoBook;
