// noinspection GraphQLUnresolvedReference

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import { Box, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import EmailBookBox from '../components/EmailBookBox'
import css from '../components/slices/wysiwyg.module.scss'
import NewsletterModal from '../components/NewsletterModal'
import CalameoBook from '../components/CalameoBook';
import useCalameoBook from '../hooks/useCalameoBook';

const Book = ({ data, pageContext }) => {
  const pageContent = data.prismic.allBooks.edges[0]
  if (!pageContent) return null
  const page = pageContent.node

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  const posts = data.prismic.allPosts.edges
  const books = data.prismic.books.edges

  const [isNewsletterFormVisible, setNewsletterFormVisible] = useState(false);

  const pageTitle = page.title ? RichText.asText(page.title) : '';

  const calameoBookId = useCalameoBook(pageContext.calameoBooks, pageTitle);

  const bookLinks = data['prismicBook']['data'];

  if (page) {
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
      >
        <Helmet>
          {page.seo_title ?
            <title>{RichText.asText(page.seo_title)}</title>
            : null}
          {page.seo_description ?
            <meta name="description" content={RichText.asText(page.seo_description)} />
            : null}
          {page.sharing_image ?
            <meta property="og:image" content={page.sharing_image.url} />
            : null}
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>

        { isNewsletterFormVisible ? <NewsletterModal onClose={() => { setNewsletterFormVisible(false) }} /> : null}


        <Box mx='auto' maxW='1100px' py={{ lg: '4rem' }} px={{ lg: '1rem' }}>
          <Grid templateColumns={{ lg: '400px 1fr' }} gridGap={{ base: '2rem', lg: '5rem' }} >
            <Box>
              {page.cover_no_text ?
                <Image display={{ lg: 'none' }} w='100%' objectPosition='bottom' src={page.cover_no_text.url} alt={page.cover_no_text.alt ? page.cover_no_text.alt : ''} />
              : null}
              {page.cover ?
                <Image display={{ base: 'none', lg: 'block' }} w='100%' objectFit='cover' objectPosition='bottom' src={page.cover.url} alt={page.cover.alt ? page.cover.alt : ''} />
              : null}
            </Box>
            <Stack spacing={{ base: '1.5rem', lg: '2rem' }} mx={{ base: '2rem', lg: '0' }} >
              { pageTitle &&
                <Heading
                  as='h1'
                  fontWeight='400'
                  fontFamily='Vesper Libre'
                  fontSize={{ base: '30px', lg: '42px' }}
                >
                  {pageTitle}
                </Heading>
              }
              { page.quote ?
                <Text
                  color='#3A3A3A'
                  fontFamily="Vesper Libre"
                  fontSize={{ base: '18px', lg: '22px' }}
                >
                  {RichText.asText(page.quote)}
                </Text>
              : null}
              { page.is_email_box_visible ?
              <Box minH='108px'>
                <EmailBookBox
                  bookLinks={bookLinks}
                  bookIdentifier={ page.book_identifier }
                  bookPdfUrl={ page.pdf_document?.url }
                />
              </Box>
              :null}
              <Box fontSize="18px" className={css.wysiwyg}
              >
                <RichText render={page.content || []} />
              </Box>

            </Stack>
          </Grid>
        </Box>
        {calameoBookId && <CalameoBook id={calameoBookId}/>}
        <SliceZone slices={page.body} bookPdfUrl={page.pdf_document ? page.pdf_document.url : null} posts={posts} books={books} />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query bookQueryV2($uid: String, $lang: String) {
  prismicBook(uid: {eq: $uid}, lang: {eq: $lang}) {
    data {
      epub_link {
        url
      }
      pdf_link {
        url
      }
      audiobook_link {
        url
      }
    }
  }
  prismic {
    allBooks(
      uid: $uid,
      lang: $lang
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          is_visible
          title
          book_identifier
          quote
          seo_title
          seo_description
          sharing_image
          cover
          cover_no_text
          intro
          content
          is_email_box_visible
          pdf_document{
            _linkType
            ... on PRISMIC__FileLink{
              url
            }
          }
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }
          body{
            ... on PRISMIC_BookBodyYoutube_player {
              type
              primary{
                video
              }
            }
            ... on PRISMIC_BookBodyQuote{
              type
              label
              primary{
                title1
              }
            }
            #... on PRISMIC_BookBodyEmail_book_box{
            #  type
            #  label
            #  primary{
            #    text_intro
            #    book_identifier
            #  }
            #}
            ... on PRISMIC_BookBodyFull_width_banner{
              type
              label
              primary{
                banner_title
              }
            }
            ... on PRISMIC_BookBodyInfo_with_image{
              type
              label
              primary{
                text
                featured_image
                text_intro
                book_identifier
                show_email_box
              }
            }
            ... on PRISMIC_BookBodyTwo_cols{
              type
              label
              primary{
                left_text
                right_text
                right_text_footer
                button_label
                button_target {
                  _linkType
                  __typename
                }
              }
            }
            ... on PRISMIC_BookBodyLatest_posts {
              type
              label
              primary{
                show_thumbnails
              }
            }
            ... on PRISMIC_BookBodyBooks {
              type
              label
            }
            ... on PRISMIC_BookBodyBio {
              type
              label
              primary{
                bio_content
                button_label
                button_target{
                  ... on PRISMIC__Document{
                    _meta {
                      uid
                      type
                      lang
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPosts (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    books:allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            uid
            type
            lang
            alternateLanguages {
              lang
              type
              uid
            }
          }
          title
          cover
          amazon_link{
            _linkType
            ... on PRISMIC__ExternalLink{
              url
            }
          }
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default Book
