import { useMemo } from 'react';

const useCalameoBook = (calameoBooks, pageTitle) => {
  const { ID } = useMemo(
    () => calameoBooks.find(({ Name }) => {
      const name = Name.trim().toLowerCase();
      const title = pageTitle.trim().toLowerCase();

      return title.includes(name);
    }) || {},
    [calameoBooks],
  );

  return ID;
};

export default useCalameoBook;
